export const howToEarnOnTowerHunt = {
  order: 1,
  name: "How to earn on Tower Hunt",
  subcategory: "Earn revenue",
  markdown: `# How to earn on Tower Hunt

  Tower Hunt is an opportunity to earn money in a new way in real estate. Read on to learn what makes Tower Hunt different, the types of revenue you can earn, and what you have to do to get started!

  ## What to expect

  In order to earn on Tower Hunt, you need two things:

  - A {% inlineRouterLink articleId="verify-your-email-address" %}verified account{% /inlineRouterLink %}
  - To take your cash earnings outside of Tower Hunt, a {% inlineRouterLink articleId="manage-payout-accounts" %}payout account{% /inlineRouterLink %}

  {% callout type="check" marginStyles="mb-4" %}
  Tower Hunt charges a 30% commission on cash earnings.
  {% /callout %}

  {% callout type="note" %}
  **Note:** Certain earnings come in the form of data credits that can be spent on Tower Hunt but not exchanged for cash. Learn more about the {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" sectionName="Revenue types" %}difference between data credits and cash{% /inlineRouterLink %}.
  {% /callout %}

  ## Indirect vs. direct revenue

  Most industry professionals accumulate useful data just by doing their jobs. Until now, that data has only been able to generate revnue *indirectly* &mdash; meaning that you use the data to gain access to *opportunities* that can *directly* generate revenue. For example, you might use a sale comp to get a meeting that leads to a new listing, or you might announce an acquisition in hopes of receiving future acquisition opportunities. Tower Hunt is different because it allows data to generate *direct* revenue &mdash; users {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay to access your data{% /inlineRouterLink %}, and Tower Hunt shares the majority of that revenue with contributors.

  {% callout type="check" %}
  Using Tower Hunt is **pure upside** since it doesn't prevent you from continuing to leverage your data in the real world for indirect revenue while it also earns direct revenue on Tower Hunt.
  {% /callout %}

  ## Revenue types

  ### Cash vs data credits

  Every datapoint on Tower Hunt is useful. Every datapoint on Tower Hunt is an opportunity to earn. However, some datapoints are offered for free while others require payment. Because of this, earnings take two different forms: **data credits** and **cash**.

  | **Revenue trigger** | **Earnings type** | **Earnings use** |
  |---|---|---|
  | Successful validation | Data credits | Datapoint access |
  | Free datapoint | Data credits | Datapoint access |
  | Paid datapoint | Cash | Delivered to {% inlineRouterLink articleId="manage-payout-accounts" %}payout accounts{% /inlineRouterLink %} |

  Your {% inlineAppIcon iconName="dollar" /%}{% inlineRouterLink articleId="key-actions-hub" %}**available balance**{% /inlineRouterLink %} combines both earnings types and acts as a shortcut to your {% inlineRouterLink articleId="track-your-earnings" %}Contributions overview{% /inlineRouterLink %}, where you can track earning and spending metrics in more detail.

  ## Revenue frequency

  Each earning activity on Tower Hunt generates one-time revenue and recurring revenue:

  ### One-time revenue

  Whenever {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}proposed changes{% /inlineRouterLink %} are {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}successfully validated{% /inlineRouterLink %}, the contributor and validators earn a one-time reward. The size of the reward increases with your {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %}.

  ### Recurring revenue

  Whenever {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}proposed changes{% /inlineRouterLink %} are {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}successfully validated{% /inlineRouterLink %}, the contributor and validators receive *attributions* for that datapoint. Each attribution entitles its user to a pro-rata share of the revenue generated by that datapoint:

  | **Attribution type** | **Pro rata share** |
  |---|---|
  | Contributor | 70% |
  | Validator | 30% divided among all validators |

  {% callout type="tip" marginStyles="mb-4" %}
  **Tip:** As you can clearly see above, it pays to contribute!
  {% /callout %}

  {% callout type="caution" %}
  **Important:** Pro rata cash earnings are calculated after Tower Hunt's 30% commission.
  {% /callout %}

  ## Next Steps

  Interested in earning? Check out {% inlineRouterLink articleId="getting-started-for-tower-hunt-contributors" %}Getting started for Tower Hunt contributors{% /inlineRouterLink %} to keep expanding your upside.`,
};
